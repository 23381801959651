import { Menu, Badge } from "antd";
import {
	AppstoreOutlined,
	DollarOutlined,
	UserOutlined,
	WalletOutlined,
	FileDoneOutlined,
	AreaChartOutlined,
	ShoppingOutlined,
	ReadOutlined,
	CarryOutOutlined,
	TeamOutlined,
	ImportOutlined,
	ExportOutlined,
	CalendarOutlined,
	BuildOutlined,
	FileTextOutlined,
} from "@ant-design/icons";
import { gql, useQuery } from "@apollo/client";
import CertificatePurchase from "components/CertificatePurchase";

const MenuRoutes = ({ handleClick, keys }) => {
	const { data } = useQuery(
		gql`
		{
			UserBadges {
				dasInstallments
			}
		}
	`,
		{
			fetchPolicy: "no-cache",
		}
	);

	console.log(data);

	return (
		<>
				<div className="menu-wrapper">
				<Menu
					onClick={handleClick}
					style={{ width: 280 }}
					defaultSelectedKeys={keys}
					selectedKeys={keys}
					openKeys={["/reps", "/invoices-wrap"]}
					mode="inline"
					expandIcon={<span></span>}
				>
					<Menu.Item icon={<AppstoreOutlined />} key={"/"}>
						Dashboard
					</Menu.Item>

					<Menu.Item icon={<DollarOutlined />} key={"/cash-launch"}>
						Caixa
					</Menu.Item>

					<Menu.Item icon={<WalletOutlined />} key={"/das"}>
						DAS
					</Menu.Item>
					<Menu.Item icon={<WalletOutlined />} key={"/das-installment"}>
						Parcelamento DAS
						{data?.UserBadges?.dasInstallments ? (
							<Badge
								count={data?.UserBadges?.dasInstallments}
								style={{ marginLeft: 10, marginTop: -2 }}
							/>
						) : null}
					</Menu.Item>

					<Menu.SubMenu
						icon={<FileTextOutlined />}
						title="Notas Fiscais"
						key="/invoices-wrap"
					>
						<Menu.Item key={"/invoices?new=true"}>Emitir Nota Fiscal</Menu.Item>
						<Menu.Item key={"/invoices"}>Notas Emitidas</Menu.Item>
					</Menu.SubMenu>

					<Menu.Item icon={<CarryOutOutlined />} key={"/services"}>
						Serviços
					</Menu.Item>

					<Menu.Item icon={<UserOutlined />} key={"/profile"}>
						Meu Perfil
					</Menu.Item>
				</Menu>

				<CertificatePurchase />
			</div>
		</>
	);
};

export default MenuRoutes;
