import React, { useEffect, useRef } from "react";
import { Skeleton, Badge } from "antd";
import User from "store/user";
import { useLocation } from "react-router-dom";

import { useQuery, gql } from "@apollo/client";

const Balance = () => {

  const mounted = useRef(false);
  const location = useLocation();

  useEffect(() => {
    refetch({
      fetchPolicy: "no-cache"
    })
  }, [location]);

  const { data, loading, error, refetch } = useQuery(
    gql`
      {
        GetUserBalance
      }
    `,
    {
      fetchPolicy: "no-cache",
    }
  );

  window.refetchBalance = () => {
    refetch({
      fetchPolicy: "no-cache",
    });
  };

  console.log(![2,3].includes(User.type));

  if (![2,3].includes(User.info.type)) return null;



  return (
    <div className="balance-info">
      <span style={{ marginRight: 10 }}>Créditos</span>

      {loading || error ? (
        <Skeleton.Button style={{ height: "20px", marginTop: 22 }} active />
      ) : null}

      {data && !loading && !error ? (
        <Badge count={data.GetUserBalance} showZero className="balance-badge" />
      ) : null}
    </div>
  );
};

export default Balance;
