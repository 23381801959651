import { gql } from '@apollo/client';

export const GET = gql`
{
	GetUserInfo {
		address {
			street
			zip_code
			number
			complement
			neighborhood
			city
			state
		}
		business {
			cnpj
			company_name
			trade_name
			email
			municipal_registration
			activity
		}
		documents {
			mother_name
			rg
			birth_date
			voter_title
			tax_code
		}
	}
}
`;


export const UPDATE = gql`
mutation UserUpdate($input: UserUpdateInput!) {
	UserUpdate(input: $input)
}
`;
