import { Menu, Button, Badge } from "antd";
import {
	AppstoreOutlined,
	UserOutlined,
	CarryOutOutlined,
	DollarOutlined,
	TagOutlined,
	MessageOutlined,
	WalletOutlined,
	RocketOutlined,
	TeamOutlined,
	BlockOutlined,
	FileTextOutlined,
	IdcardOutlined,
	StarOutlined,
	FileSyncOutlined,
	InsertRowLeftOutlined,
} from "@ant-design/icons";
import User from "store/user";
import CertificatePurchase from "components/CertificatePurchase";
import Insurance from "components/Insurance";
import { gql, useMutation, useQuery } from "@apollo/client";

const MenuRoutes = ({ handleClick, keys, userInfo }) => {
	const theme = import.meta.env.VITE_APP_THEME;
	const siderView = true;

	const [saveLead] = useMutation(gql`
		mutation SaveLead($input: LeadInput!)	 {
			SaveLead(input: $input)
		}
	`);

	const { data } = useQuery(
		gql`
	{
		ChatMessagesPartnerUnread 
	}
`,
		{
			fetchPolicy: "no-cache",
			pollInterval: 5000,
		}
	);

	const links = useQuery(
		gql`
			query GetAvailableLinks($app: String!) {
				GetAvailableLinks(app: $app)
			}
		`,
		{
			fetchPolicy: "no-cache",
			variables: {
				app: import.meta.VITE_APP_THEME || "openmei",
			},
		}
	);

	return (
		<>
			<div className="menu-wrapper">
				<Menu
					onClick={handleClick}
					style={{ width: 280 }}
					defaultSelectedKeys={keys}
					selectedKeys={keys}
					openKeys={["expenses"]}
					mode="inline"
					expandIcon={<span></span>}
				>
					<Menu.Item icon={<AppstoreOutlined />} key={"/"}>
						Dashboard
					</Menu.Item>

					<Menu.Item icon={<UserOutlined />} key={"/users"}>
						MEI
					</Menu.Item>

					<Menu.Item icon={<FileTextOutlined />} key={"/invoices"}>
						Notas Fiscais
					</Menu.Item>

					<Menu.Item icon={<DollarOutlined />} key={"/cash-launch"}>
						Caixa
					</Menu.Item>

					{/* <Menu.Item icon={<ImportOutlined />} key={"/bills/income"}>
          Contas a Receber
        </Menu.Item> */}

					<Menu.Item icon={<WalletOutlined />} key={"/das"}>
						DAS
					</Menu.Item>

					<Menu.Item icon={<WalletOutlined />} key={"/das-installment"}>
						DAS de Parcelamento
					</Menu.Item>

					<Menu.Item icon={<WalletOutlined />} key={"/active-debts"}>
						Dívida Ativa
					</Menu.Item>

					{/* {!import.meta.env?.VITE_WHITELABEL && (
						<Menu.Item icon={<DollarOutlined />} key={"/credits"}>
							Créditos
						</Menu.Item>
					)} */}

					{/* <Menu.Item icon={<TagOutlined />} key={"/vouchers"}>
						Vouchers
					</Menu.Item> */}

					<Menu.Item icon={<TeamOutlined />} key={"/admins"}>
						Administradores
					</Menu.Item>

					{userInfo.type === 2 && (
						<>
							<Menu.Item icon={<IdcardOutlined />} key={"/companies"}>
								Empresas
							</Menu.Item>
						</>
					)}

					<Menu.Item icon={<BlockOutlined />} key={"/groups"}>
						Grupos
					</Menu.Item>

					<Menu.Item icon={<MessageOutlined />} key={"/messages"}>
						Mensagens <Badge count={data?.ChatMessagesPartnerUnread} />
					</Menu.Item>

					<Menu.Item icon={<TagOutlined />} key={"/tickets"}>
						Tickets Abertos
					</Menu.Item>

					<Menu.Item icon={<CarryOutOutlined />} key={"/services"}>
						Serviços
					</Menu.Item>

					<Menu.Item icon={<FileSyncOutlined />} key={"/documents"}>
						Docs. Enviados/Recebidos
					</Menu.Item>

					{import.meta.env.VITE_APP_PARTNER_REVIEWS === "1" ? (
						<Menu.Item icon={<StarOutlined />} key={"/reviews"}>
							Avaliações
						</Menu.Item>
					) : null}

					<Menu.Item icon={<InsertRowLeftOutlined />} key={"/plans"}>
						Meus Planos
					</Menu.Item>

					<Menu.Item icon={<UserOutlined />} key={"/tutorials"}>
						Tutoriais
					</Menu.Item>

					<Menu.Item icon={<UserOutlined />} key={"/profile"}>
						Meu Perfil
					</Menu.Item>

					<Menu.Item icon={<RocketOutlined />} key={"/kickoff"}>
						Kickoff
					</Menu.Item>

					<Menu.Item icon={<RocketOutlined />} key={"/prospects"}>
						CRM Prospecção
					</Menu.Item>

					<Menu.Item icon={<RocketOutlined />} key={"/uau"}>
						UAU CRM Prospecção
					</Menu.Item>
				</Menu>
				{links?.data?.GetAvailableLinks?.length &&
				links?.data?.GetAvailableLinks.indexOf("certificate") > -1 ? (
					<CertificatePurchase />
				) : null}

				{links?.data?.GetAvailableLinks?.length &&
				links?.data?.GetAvailableLinks.indexOf("santander") > -1 ? (
					<div
						style={{
							padding: 10,
							paddingTop: 0,
						}}
					>
						{import.meta.env.VITE_APP_THEME === "simei" ? (
							<Button
								type="primary"
								style={{
									width: "100%",
									color: "white",
									backgroundColor: "#324396",
									borderColor: "#324396",
								}}
								onClick={() => {
									saveLead({
										variables: {
											input: {
												source: "r2bank",
											},
										},
									});

									let url = "https://app.r2bank.com.br/login";

									window.open(url);
								}}
							>
								R2 BANK
							</Button>
						) : (
							<Button
								type="primary"
								style={{
									width: "100%",
									color: "white",
									backgroundColor: "#cc0100",
									borderColor: "#cc0100",
								}}
								onClick={() => {
									saveLead({
										variables: {
											input: {
												source: "santander",
											},
										},
									});

									let url =
										"https://www.santander.com.br/conta-empresarial?id=5e0015c64068c090fcb46595205df9b7da21ecfc508d3b96e57e12c8283a4977";

									if (import.meta.VITE_APP_THEME === "simei") {
										url = "";
									}

									window.open(url);
								}}
							>
								Santander MEI+
							</Button>
						)}
					</div>
				) : null}
				
				{links?.data?.GetAvailableLinks?.length &&
				links?.data?.GetAvailableLinks.indexOf("insurance") > -1 ? (
					<Insurance />
				) : null}

				{links?.data?.GetAvailableLinks?.length &&
				links?.data?.GetAvailableLinks.indexOf("inove") > -1 ? (
					<div
						style={{
							padding: 10,
							paddingTop: 0,
						}}
					>
						<Button
							type="primary"
							style={{
								width: "100%",
								color: "white",
								backgroundColor: "#f48120",
								borderColor: "#f48120",
							}}
							onClick={() => {
								window.open("http://www.inoveclick.com.br/");
								saveLead({
									variables: {
										input: {
											source: "inoveclick",
										},
									},
								});
							}}
						>
							Marketing Digital
						</Button>
					</div>
				) : null}
			</div>
		</>
	);
};

export default MenuRoutes;
