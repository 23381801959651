import React, { useState, useEffect, useRef, Suspense } from "react";
import User from "store/user";
import {
	Layout,
	Avatar,
	Menu,
	Dropdown,
	Input,
	Form,
	AutoComplete,
	Button,
} from "antd";
import { Link, NavLink } from "react-router-dom";
import debounce from "functions/debounce";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import * as Queries from "./Queries";
import { useHistory } from "react-router-dom";

import Balance from "./Balance";
import Chat from "./Chat";

import {
	UserOutlined,
	SearchOutlined,
	LoadingOutlined,
} from "@ant-design/icons";

const { Header } = Layout;
const { Option } = AutoComplete;

const ClientModal = React.lazy(() => import("scenes/clients/Modal"));
const ProductModal = React.lazy(() => import("scenes/products/Modal"));
const BillingReceiptModal = React.lazy(() =>
	import("scenes/billing-receipts/Modal")
);

function HeaderView(props) {
	const wrapperRef = useRef(null);
	const history = useHistory();

	const [results, setResults] = useState(null);

	const [modal, setModal] = useState(null);
	const [modalId, setModalId] = useState(null);

	const [search, { loading, data }] = useLazyQuery(Queries.SEARCH, {
		fetchPolicy: "no-cache",
	});

	const chatAvailable = useQuery(
		gql`
		{
			ChatAvailable
		}
	`,
		{
			fetchPolicy: "no-cache",
		}
	);

	const onSearch = debounce((e) => searchAction(e), 500);

	const searchAction = async (term) => {
		if (term) {
			await search({
				variables: {
					term,
				},
			});
		} else {
			setResults(null);
		}
	};

	useEffect(() => {
		if (data?.Search?.length) {
			setResults(data.Search);
		} else {
			setResults([]);
		}
	}, [data]);

	useEffect(() => {
		window.logout = logout;
	}, []);

	const logout = () => {
		User.logout().then(() => {
			history.push("/login");
		});
	};

	useEffect(() => {
		function handleClickOutside(event) {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				setResults([]);
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [results]);

	const submenu = () => {
		return (
			<Menu>
				<Menu.Item>
					<a target="_blank" rel="noopener noreferrer" onClick={logout}>
						Sair
					</a>
				</Menu.Item>
			</Menu>
		);
	};

	const onSelect = (item) => {
		setModal(JSON.parse(item.data));
		setModalId(item.id);
		setResults([]);
	};


	return (
		<Header className="header-view">
			{modalId ? (
				<Suspense fallback={null}>
					{modalId === "client" ? (
						<ClientModal
							visible={true}
							data={modal}
							close={() => {
								setModalId(null);
							}}
							refetch={() => null}
						/>
					) : null}
					{modalId === "product" ? (
						<ProductModal
							visible={true}
							data={modal}
							close={() => {
								setModalId(null);
							}}
							refetch={() => null}
						/>
					) : null}
					{modalId === "billing_receipt" ? (
						<BillingReceiptModal
							visible={true}
							data={modal}
							close={() => {
								setModalId(null);
							}}
							refetch={() => null}
						/>
					) : null}
				</Suspense>
			) : null}

			<div className="header-inset-view">
				<div className="search-view">
					<Input
						size="large"
						placeholder="Buscar"
						prefix={<SearchOutlined />}
						onChange={(e) => {
							const { value } = e.target;
							onSearch(value);
						}}
					/>

					{results?.length ? (
						<div className="search-autocomplete" ref={wrapperRef}>
							{results.map((item, index) => {
								return (
									<div
										className="search-autocomplete-item"
										key={`search_${index}`}
									>
										<span>{item.label}</span>

										<div className="inner-results">
											{item.options.map((itemInner, indexInner) => {
												return (
													<div
														className="search-inner-item"
														key={`search_${index}_${indexInner}`}
														onClick={() => onSelect(itemInner)}
													>
														<span>{itemInner.label}</span>
													</div>
												);
											})}
										</div>
									</div>
								);
							})}
						</div>
					) : null}
				</div>

				{(User.info.type === 2 || User.info.type === 3) &&
				import.meta.env.VITE_BALANCE_ENABLED === "1" ? (
					<Balance />
				) : null}

				{User.info.type === 1 && chatAvailable?.data?.ChatAvailable && <Chat />}

				<div className="user-info">
					<span className="user-name">
						<strong>{User.get("name")}</strong>
					</span>
					<div className="user-avatar">
						<UserOutlined
							style={{ color: "#593d88", fontSize: "20px", cursor: "pointer" }}
						/>
					</div>
					<Button type="link" onClick={logout}>
						Sair
					</Button>
				</div>
			</div>
		</Header>
	);
}

export default HeaderView;
