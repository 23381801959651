import React, { useEffect, useState } from "react";
import { Form, Input, Button, Modal } from "antd";
import useImperativeQuery from "functions/hooks/useImperativeQuery";
import * as Queries from "./Queries";
import Swal from "sweetalert2";
import isEmail from "validator/lib/isEmail";
import { useApolloClient, useMutation } from "@apollo/client";
import User from "store/user";
import Loading from "main/components/Loading/Page";
import { gql } from "@apollo/client";
import axios from "axios";
import DOMPurify from "dompurify";
import { useImmer } from "use-immer";

function Login({ history }) {
	const client = useApolloClient();
	const [state, setState] = useState({});
	const [loading, setLoading] = useState(false);
	const [loadingTerms, setLoadingTerms] = useState(false);
	const [loadingView, setLoadingView] = useState(true);
	const loginAction = useImperativeQuery(Queries.LOGIN);

	const [saveTerms, saving] = useMutation(
		gql`
		mutation UserTermsAccepted($token: String!) {
			UserTermsAccepted(token: $token)
		}
	`,
		{
			onCompleted: async (data) => {
				if (data?.UserTermsAccepted) {
					await User.setLogin(terms.data, client);
					history.replace("/");
				}
			},
		}
	);

	const [terms, setTerms] = useImmer({
		visible: false,
	});
	const [privacy, setPrivacy] = useImmer({
		visible: false,
	});

	const login = async () => {
		try {
			if (!state.email || !isEmail(state.email))
				return Swal.fire({
					title: "Erro",
					text: "Por favor, insira um e-mail válido.",
					type: "error",
					confirmButtonText: "OK",
				});

			if (!state.password)
				return Swal.fire({
					title: "Erro",
					text: "Por favor, insira uma senha válida.",
					type: "error",
					confirmButtonText: "OK",
				});
			setLoading(true);

			let input = { ...state };

			const { VITE_PARTNER_ID, VITE_APP_TYPE } = import.meta.env;

			if (VITE_PARTNER_ID && VITE_PARTNER_ID !== "") {
				input.partner_id = VITE_PARTNER_ID;
				input.type = VITE_APP_TYPE;
			}

			console.log(input);

			const { data, errors } = await loginAction({
				input,
			});

			setLoading(false);

			if (errors) {
				const errorMessage =
					errors[0].message || "Não foi possível fazer seu login no momento.";
				return Swal.fire({
					title: "Erro",
					text: errorMessage,
					type: "error",
					confirmButtonText: "OK",
				});
			}

			if (data?.Login?.terms && data?.Login?.terms_url) {
				let termsHTML = await axios
					.get(data.Login.terms_url)
					.then((res) => res.data);

				let privacyHTML = await axios
					.get(data.Login.privacy_url)
					.then((res) => res.data);

				setPrivacy((d) => {
					d.content = DOMPurify.sanitize(privacyHTML);
				});

				setTerms({
					open: true,
					content: DOMPurify.sanitize(termsHTML),
					data: data.Login,
				});
			} else {
				await User.setLogin(data.Login, client);
				history.replace("/");
			}
		} catch (e) {
			setLoading(false);
			console.log(e);
			return Swal.fire({
				title: "Erro",
				text: "Não foi possível fazer login no momento. Por favor, tente novamente mais tarde.",
				type: "error",
				confirmButtonText: "OK",
			});
		}
	};

	const checkUser = async () => {
		let data = await localStorage.getItem("user");

		if (data) {
			data = JSON.parse(data);

			client
				.query({
					query: gql`
              				query AuthCheck($token: String!) {
									AuthCheck(token: $token) {
										pending
										result
									}
								}
            `,
					fetchPolicy: "no-cache",
					variables: {
						token: data.token,
					},
				})
				.then(async (check) => {
					if (check.data.AuthCheck.result) {
						history.replace("/");
					} else {
						setLoadingView(false);
					}
				})
				.catch((err) => {
					setLoadingView(false);
				});
		} else {
			setLoadingView(false);
		}
	};

	useEffect(() => {
		checkUser();
	}, []);

	if (loadingView) return <Loading />;

	const logo = new URL(`theme/logo.png`, import.meta.url).href;

	return (
		<div className="login">
			<div className="login-wrapper">
				<div className="login-inset">
					<div className="logo-wrapper">
						<img src={logo} alt="" />
					</div>

					<Modal
						title="Política de Privacidade"
						visible={privacy.open}
						onOk={async () => {
							setPrivacy(d => {
								d.open = false;
							})
						}}
						okText={"OK"}
						onCancel={() => {
							setPrivacy(d => {
								d.open = false;
							})
						}}
						width={750}
					>
						<div className="terms-wrapper">
							<div
								dangerouslySetInnerHTML={{
									__html: privacy.content,
								}}
							/>
						</div>
					</Modal>

					<Modal
						title="Termos de Uso"
						visible={terms.open}
						onOk={async () => {
							setLoadingTerms(true);
							await saveTerms({
								variables: {
									token: terms.data.token,
								},
							});
						}}
						okText={"Aceitar"}
						onCancel={() => {}}
						width={750}
						confirmLoading={loadingTerms}
					>
						<div className="terms-wrapper">
							<div
								dangerouslySetInnerHTML={{
									__html: terms.content,
								}}
							/>
						</div>
						<div
							style={{
								marginTop: 15,
							}}
						>
							Ao prosseguir, você aceita nossos termos de uso e nossa{" "}
							<a
								href={"#"}
								onClick={(e) => {
									e.preventDefault();
									setPrivacy((d) => {
										d.open = true;
									});
								}}
							>
								política de privacidade
							</a>
							.
						</div>
					</Modal>

					<Form onSubmit={null} layout={"vertical"} className="login-form">
						<Form.Item>
							<Input
								rules={[{ required: true }]}
								placeholder="E-mail"
								size="large"
								onChange={(event) => {
									const { value } = event.target;
									setState((state) => {
										return {
											...state,
											email: value,
										};
									});
								}}
							/>
						</Form.Item>
						<Form.Item>
							<Input
								rules={[{ required: true }]}
								type="password"
								placeholder="Senha"
								size="large"
								onChange={(event) => {
									const { value } = event.target;
									setState((state) => {
										return {
											...state,
											password: value,
										};
									});
								}}
							/>
						</Form.Item>

						<div className="options">
							<a className="login-form-forgot" href="reset-password">
								Esqueci minha senha
							</a>
							<Button
								size={"large"}
								loading={loading}
								onClick={login}
								type="primary"
								htmlType="submit"
								className="login-form-button"
							>
								Continuar
							</Button>
						</div>

						<div className="register">
							<span>Ainda não é cadastrado?</span>

							<Button
								size={"large"}
								onClick={() => {
									window.location.href =
										import.meta.env.VITE_URL_SIGNUP ||
										"https://www.openmei.com.br/fale-conosco?pacote=pacote-super";
								}}
								type="primary"
								htmlType="submit"
								className="login-form-button"
							>
								Cadastre-se
							</Button>
						</div>
					</Form>
				</div>
			</div>
		</div>
	);
}

export default Login;
