import { useEffect, useState } from "react";
import { Button, Modal, Spin, Empty, Card, Row, Col, notification } from "antd";
import { gql, useLazyQuery } from "@apollo/client";

const CertificatePurchase = ({ handleClick, keys }) => {
	const [modal, setModal] = useState({
		open: false,
	});
	const [loadingIndex, setLoadingIndex] = useState(null);

	const [getCertificates, { loading, data }] = useLazyQuery(
		gql`
		query GetPurchaseCertificates {
			GetPurchaseCertificates {
				name
            id
			}
		}
	`,
		{
			fetchPolicy: "no-cache",
		}
	);

	const [getURL, { loading: loadingGet }] = useLazyQuery(
		gql`
		query GetCertificateBuyURL($id: String) {
			GetCertificateBuyURL(id: $id) {
				url
			}
		}
	`,
		{
			fetchPolicy: "no-cache",
		}
	);

	useEffect(() => {
		if (modal.open) {
			getCertificates();
		}
	}, [modal]);

	const getURLAction = async (id, index) => {
		if (!loadingIndex) {
			try {
				setLoadingIndex(index);
				let response = await getURL({
					variables: {
						id,
					},
				});
				if (response?.data?.GetCertificateBuyURL?.url) {
					window.open(response?.data?.GetCertificateBuyURL?.url);
					setLoadingIndex(null);
				}
			} catch (error) {
				notification.error({
					message: "Erro",
					description: "Não foi possível prosseguir no momento.",
				});
				setLoadingIndex(null);
			}
		}
	};

	const theme = import.meta.env.VITE_APP_THEME;

	if (theme === "openmei") {
		return (
			<>
				<Modal
					title="Adquirir Certificado"
					open={modal.open}
					onOk={() => {
						setModal({ open: false });
					}}
					width={800}
					onCancel={() => {
						setModal({ open: false });
					}}
					footer={null}
				>
					{loading ? (
						<div style={{ padding: 20, textAlign: "center" }}>
							<Spin />
						</div>
					) : null}

					{!loading && data?.GetPurchaseCertificates?.length ? (
						<Row gutter={20}>
							{data.GetPurchaseCertificates.map((item, index) => {
								return (
									<Col span={8}>
										<Card key={`cert_${index}`}>
											<p>{item?.name ?? "-"}</p>
											<Button
												type="primary"
												loading={loadingIndex === index}
												onClick={() => {
													getURLAction(item.id, index);
												}}
											>
												Selecionar
											</Button>
										</Card>
									</Col>
								);
							})}
						</Row>
					) : null}

					{!loading && !data?.GetPurchaseCertificates?.length ? (
						<Empty description={"Nenhum certificado encontrado"} />
					) : null}
				</Modal>

				<div style={{ padding: 10 }}>
					<Button
						type="primary"
						style={{
							width: "100%",
							color: "white",
							backgroundColor: "#2af14d",
							borderColor: "#2af14d",
						}}
						onClick={() => {
							setModal({ open: true });
						}}
					>
						Adquira seu Certificado
					</Button>
					<Button
						type="primary"
						style={{ width: "100%", marginTop: 10 }}
						onClick={() => {
							window.open(
								"https://lp.cora.com.br/coraliados/?code=openmei-servicos-de-tecnologia-e-escritorio&n=Openmei%20Servicos%20De%20Tecnologia%20E%20Escrit%C3%B3rio"
							);
						}}
					>
						Abra sua conta no Cora
					</Button>
				</div>
			</>
		);
	}

	return (
		<>
			{import.meta.env.VITE_APP_BANK_REFER && (
				<div style={{ padding: 10 }}>
					<Button
						type="default"
						style={{ width: "100%" }}
						onClick={() => {
							window.open(import.meta.env.VITE_APP_BANK_REFER);
						}}
					>
						Abra sua conta digital
					</Button>
				</div>
			)}
			{import.meta.env.VITE_APP_CERT_PURCHASE && (
				<>
					<Modal
						title="Adquirir Certificado"
						open={modal.open}
						onOk={() => {
							setModal({ open: false });
						}}
						width={800}
						onCancel={() => {
							setModal({ open: false });
						}}
						footer={null}
					>
						{loading ? (
							<div style={{ padding: 20, textAlign: "center" }}>
								<Spin />
							</div>
						) : null}

						{!loading && data?.GetPurchaseCertificates?.length ? (
							<Row gutter={20}>
								{data.GetPurchaseCertificates.map((item, index) => {
									return (
										<Col span={8}>
											<Card key={`cert_${index}`}>
												<p>{item?.name ?? "-"}</p>
												<Button
													type="primary"
													loading={loadingIndex === index}
													onClick={() => {
														getURLAction(item.id, index);
													}}
												>
													Selecionar
												</Button>
											</Card>
										</Col>
									);
								})}
							</Row>
						) : null}

						{!loading && !data?.GetPurchaseCertificates?.length ? (
							<Empty description={"Nenhum certificado encontrado"} />
						) : null}
					</Modal>

					<div style={{ padding: 10 }}>
						<Button
							type="primary"
							style={{
								width: "100%",
								color: "white",
								backgroundColor: "#2af14d",
								borderColor: "#2af14d",
							}}
							onClick={() => {
								setModal({ open: true });
							}}
						>
							Adquira seu Certificado
						</Button>
					</div>
				</>
			)}
		</>
	);

};

export default CertificatePurchase;
