import React, { useState } from "react";
import { Form, Input, Button, Checkbox } from "antd";

//import "assets/scss/login.scss";

import { useMutation } from "@apollo/client";

import * as Queries from "./Queries";
import Swal from "sweetalert2";

import { useParams, useHistory } from "react-router-dom";

function Login() {
	const [password, setPassword] = useState(null);
	const [loading, setLoading] = useState(false);

	let { token } = useParams();
	let history = useHistory();

	const [setAction] = useMutation(Queries.NEW_PASSWORD);

	const set = async () => {
		if (!password)
			return Swal.fire({
				title: "Erro",
				text: "Por favor, insira uma senha válida.",
				type: "error",
				confirmButtonText: "OK",
			});

		setLoading(true);

		try {
			const res = await setAction({
				variables: {
					token,
					password,
				},
			});

			if (res?.data?.UserNewPassword) {
				setLoading(false);
				Swal.fire({
					title: "Sucesso",
					text: "Sua senha foi redefinida com sucesso!",
					type: "success",
					confirmButtonText: "OK",
				}).then((result) => {
					if (result.isConfirmed) {
						history.push("/login");
					}
				});
			} else {
				setLoading(false);
				Swal.fire({
					title: "Erro",
					text: "Não foi possível redefinir sua senha no momento. Por favor, tente novamente mais tarde.",
					type: "error",
					confirmButtonText: "OK",
				});
			}
		} catch (e) {
			console.log(e.message);

			setLoading(false);

			Swal.fire({
				title: "Erro",
				text: e.message,
				type: "error",
				confirmButtonText: "OK",
			});
		}
	};

	const logo = new URL(`theme/logo.png`, import.meta.url).href;

	return (
		<div className="login">
			<div className="login-wrapper">
				<div className="login-inset">
					<div className="logo-wrapper">
						<img src={logo} alt="" />
					</div>

					<p>Informe sua nova senha abaixo:</p>

					<Form onSubmit={null} layout={"vertical"} className="login-form">
						<Form.Item>
							<Input
								rules={[{ required: true }]}
								type="password"
								placeholder="Senha"
								size="large"
								onChange={(event) => {
									const { value } = event.target;
									setPassword(value);
								}}
							/>
						</Form.Item>

						<div className="options">
							<Button
								size={"large"}
								loading={loading}
								onClick={set}
								type="primary"
								htmlType="submit"
								className="login-form-button"
							>
								Continuar
							</Button>
						</div>
					</Form>
				</div>
			</div>
		</div>
	);
}

export default Login;
