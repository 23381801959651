import { useQuery } from '@apollo/client';

const useImperativeQuery = (query, vars = {}) => {
  const { refetch } = useQuery(query, {
   skip: true, 
   errorPolicy: 'all',
   ...vars
  });
  const imperativelyCallQuery = (variables) => {
    return refetch(variables);
  } 
  return imperativelyCallQuery;
}


export default useImperativeQuery;