import React, { useEffect, useState } from "react";
import User from "store/user";
import CertificateUser from "./User";
import CertificatePartner from "./Partner";

function View(props) {
	if (User.info.type === 1) {
		return <CertificateUser/>;
	}
	if (User.info.type === 2 || User.info.type === 3) {
		return (
			<CertificatePartner/>
		);
	}
	return null;
}

export default View;
