import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import User from "store/user";
import * as Menu from "./Menus";

const { Sider } = Layout;

function View(props) {
	const [keys, setKeys] = useState(["/"]);

	const handleClick = (e) => {
		props.history.push(e.key);
	};

	const setActiveRoute = () => {
		const { pathname } = props.history.location;
		if (pathname.indexOf("/user") > -1) {
			setKeys(["/users"]);
		} else {
			setKeys([pathname]);
		}
	};

	useEffect(() => {
		props.history.listen(() => {
			setActiveRoute();
		});
		const { pathname } = props.history.location;
		if (pathname.indexOf("/user") > -1) {
			setKeys(["/users"]);
		} else {
			setKeys([pathname]);
		}
	}, []);

	const notAllowedBillingSubtypes = ["management"];

	const renderMenu = () => {
		if (User.info.type === 1 && User.info.subtype === "light") {
			return (
				<Menu.UserLight
					userInfo={User.info}
					handleClick={handleClick}
					keys={keys}
				/>
			);
		}

		if (
			User.info.type === 1 ||
			(User.info.type === 2 &&
				notAllowedBillingSubtypes.includes(User.info.subtype))
		) {
			return (
				<Menu.User userInfo={User.info} handleClick={handleClick} keys={keys} />
			);
		}

		if (User.info.type === 2 && User.info.subtype === "light") {
			return (
				<Menu.PartnerLight
					userInfo={User.info}
					handleClick={handleClick}
					keys={keys}
				/>
			);
		}

		if (
			(User.info.type === 2 &&
				!notAllowedBillingSubtypes.includes(User.info.subtype)) ||
			User.info.type === 3
		) {
			return (
				<Menu.Partner
					userInfo={User.info}
					handleClick={handleClick}
					keys={keys}
				/>
			);
		}

		return null;
	};

	const imgUrl = new URL(`theme/logo.png`, import.meta.url).href;

	return (
		<Sider
			width={280}
			className={"sider"}
			breakpoint="lg"
			collapsedWidth="0"
			zeroWidthTriggerStyle={{
				color: "#95c050",
			}}
		>
			<div className="logo-wrapper">
				<img src={imgUrl} alt="" />

				{[2, 3].includes(User.info.type) &&
					import.meta.env.VITE_APP_THEME !== "openmei" && !import.meta.env.VITE_APP_HIDE_POWERED && (
						<div className="powered">
							powered by
							<img src="/logo-openmei.png" alt="" />
						</div>
					)}
			</div>

			{renderMenu()}
		</Sider>
	);
}

export default View;
