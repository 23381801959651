import { gql } from '@apollo/client';

export const SEARCH = gql`
  query Search($term: String!) {
    Search(term: $term) {
      	label
        options {
          label
          value
          id
          data
        }
    }
  }
`;
