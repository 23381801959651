import { gql } from '@apollo/client';

export const RESET_PASSWORD = gql`
mutation ResetUserPassword($email: String!) {
  ResetUserPassword(email: $email)
}
`;


export const NEW_PASSWORD = gql`
mutation UserNewPassword($password: String!, $token: String!) {
  UserNewPassword(password: $password, token: $token)
}
`;
