import React from "react";
import { Layout } from "antd";

import Routes from "../index";

import Sider from "components/Sider";
import Header from "components/Header";
import AppWrapper from "functions/appWrapper";
import { useApolloClient } from "@apollo/client";

const { Footer, Content } = Layout;

function Main(props) {

	const client = useApolloClient();

	return (
		<AppWrapper client={client}>
			<Layout className="main-wrapper">
				<Sider history={props.history} />
				<Layout>
					<Header {...props} />
					<Content>
						<Routes />
					</Content>
				</Layout>
			</Layout>
		</AppWrapper>
	);
}

export default Main;
