import { ApolloClient, InMemoryCache } from '@apollo/client';
import {createUploadLink} from 'apollo-upload-client';
import { from, HttpLink } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from "@apollo/client/link/error";
import Emitter from "functions/emitter";
import { setContext } from "@apollo/client/link/context";
import User from 'store/user';

const ErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) console.log('graphQLErrors', graphQLErrors);
  if (networkError) console.log('networkError', networkError);

  if ([401, 403].includes(networkError?.statusCode)) {
		Emitter.emit("LOGOUT");
	}
});


const uploadLink = createUploadLink({ 
  uri: import.meta.env.VITE_APP_API 
});

const AuthLink = setContext(async (_, { headers }) => {

  let user = await localStorage.getItem("user");
  let token;
  if(user) {
      user = JSON.parse(user);
      if(user.token) {
        token = user.token;
      }
  }
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    }
  }
});

const additiveLink = from([
  ErrorLink,
  AuthLink,
  uploadLink
]);

const client = new ApolloClient({
  link: additiveLink,
  cache: new InMemoryCache({
  	addTypename: false
  })
});

export default client;