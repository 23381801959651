import { Menu, Button } from "antd";
import {
	AppstoreOutlined,
	UserOutlined,
	CarryOutOutlined,
	DollarOutlined,
	TagOutlined,
	MessageOutlined,
	WalletOutlined,
	ImportOutlined,
	TeamOutlined,
	BlockOutlined,
	FileTextOutlined,
	IdcardOutlined,
	StarOutlined,
	InsertRowLeftOutlined,
} from "@ant-design/icons";
import User from "store/user";
import CertificatePurchase from "components/CertificatePurchase";

const MenuRoutes = ({ handleClick, keys, userInfo }) => {
	const theme = import.meta.env.VITE_APP_THEME;
	const siderView = true;

	return (
		<>
			<div className="menu-wrapper">
				<Menu
					onClick={handleClick}
					style={{ width: 280 }}
					defaultSelectedKeys={keys}
					selectedKeys={keys}
					openKeys={["expenses"]}
					mode="inline"
					expandIcon={<span></span>}
				>
					<Menu.Item icon={<AppstoreOutlined />} key={"/"}>
						Dashboard
					</Menu.Item>

					<Menu.Item icon={<UserOutlined />} key={"/users"}>
						MEI
					</Menu.Item>

					<Menu.Item icon={<FileTextOutlined />} key={"/invoices"}>
						Notas Fiscais
					</Menu.Item>

					<Menu.Item icon={<DollarOutlined />} key={"/cash-launch"}>
						Caixa
					</Menu.Item>

					{/* <Menu.Item icon={<ImportOutlined />} key={"/bills/income"}>
          Contas a Receber
        </Menu.Item> */}

					<Menu.Item icon={<WalletOutlined />} key={"/das"}>
						DAS
					</Menu.Item>

					<Menu.Item icon={<WalletOutlined />} key={"/das-installment"}>
						Parcelamento de DAS
					</Menu.Item>

					<Menu.Item icon={<TeamOutlined />} key={"/admins"}>
						Administradores
					</Menu.Item>


					<Menu.Item icon={<MessageOutlined />} key={"/tickets"}>
						Tickets Abertos
					</Menu.Item>

					<Menu.Item icon={<CarryOutOutlined />} key={"/services"}>
						Serviços
					</Menu.Item>

					<Menu.Item icon={<UserOutlined />} key={"/profile"}>
						Meu Perfil
					</Menu.Item>
				</Menu>
				<CertificatePurchase />
			</div>
		</>
	);
};

export default MenuRoutes;
