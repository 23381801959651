import { Button, Drawer, Input, Badge } from "antd";
import { MessageOutlined } from "@ant-design/icons";
import { useState, useEffect, useRef } from "react";
import { useLazyQuery, useMutation, gql } from "@apollo/client";
import { useImmer } from "use-immer";
import { Comment, Form, Empty, Spin } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import relativeTime from "dayjs/plugin/relativeTime";
import _ from "lodash";

const { TextArea } = Input;

dayjs.locale("pt-br");
dayjs.extend(relativeTime);

const Balance = () => {
	const [badgeValue, setBadge] = useState(0);
	const [open, setOpen] = useState(false);
	const [state, setState] = useImmer({});
	const [messages, setMessages] = useImmer([]);

	const scrollRef = useRef(null);
	const mounted = useRef(false);
	const interval = useRef(null);

	const INTERVAL_TIME = 5000;

	const [sendRead] = useMutation(gql`
			mutation ChatMessagesRead {
				ChatMessagesRead
			}
	`);

	const [load, conversation] = useLazyQuery(
		gql`
		query ChatMessages {
			ChatMessages {
				_id
				createdAt
				message
				read
				author {
					name
					me
				}
			}
		}
	`,
		{
			fetchPolicy: "no-cache",
			onCompleted: (response) => {
				if (response?.ChatMessages?.length) {
					setMessages(response?.ChatMessages);

					let badge = response.ChatMessages.filter((i) => {
						return i.author.me !== true && i.read === false;
					});

					if (badge.length && !open) {
						setBadge(badge.length);
					} else {
						setBadge(0);
					}

					if(open) {
						sendRead();
					}

					if(!mounted.current) {
						mounted.current = true;
						scrollRef.current?.scrollIntoView({ behavior: "instant" });
					} else {
						scrollRef.current?.scrollIntoView({ behavior: "smooth" });
					}
					
					
				}
			},
		}
	);

	const [send, { loading: sendingLoading }] = useMutation(
		gql`
			mutation ChatMessage($message: String!) {
				ChatMessage(message: $message) {
					_id
					createdAt
					message
					author {
						name
						me
					}
				}
			}
	`,
		{
			fetchPolicy: "no-cache",
		}
	);

	const sendMessage = async () => {
		let res = await send({
			variables: {
				message: state.message,
			},
		});

		if (res?.data?.ChatMessage?._id) {
			setMessages([...messages, res.data.ChatMessage]);
			setState({});
			scrollRef.current?.scrollIntoView({ behavior: "smooth" });
		}
	};

	const onOpen = () => {


		load({
			variables: {},
		});

		setBadge(0);
		sendRead();

		clearInterval(interval.current);
		interval.current = setInterval(() => {
			load({
				variables: {},
			});
		}, INTERVAL_TIME);

		scrollRef.current?.scrollIntoView({ behavior: "instant" });
		

	}

	useEffect(() => {
		load({
			variables: {},
		});

		if(scrollRef.current) scrollRef.current?.scrollIntoView({ behavior: "smooth" }); 

		interval.current = setInterval(() => {
			load({
				variables: {},
			});
		}, INTERVAL_TIME);

		return () => {
			if(interval.current) {
				clearInterval(interval.current)
			}
		}

	}, []);

	const renderAuthor = (author) => {
		const getUserType = (type) => {
			if (type === "admin") return "Administrador";
			if (type === "partner") return "Parceiro";
			if (type === "partner_admin") return "Adm. Parceiro";
			if (type === "user") return "MEI";
		};

		return (
			<div>
				{author.name}{" "}
				{!author.me ? (
					<div className={`badge ${author.user_type}`}>
						{getUserType(author.user_type)}
					</div>
				) : null}
			</div>
		);
	};

	return (
		<div
			className="chat"
			style={{
				marginLeft: 20,
			}}
		>
			<Drawer
				title="Chat"
				placement="right"
				onClose={() => {
					setOpen(false);
				}}
				width={600}
				open={open}
				afterOpenChange={(open) => {
					if (open) {
						onOpen();
					}
				}}
			>
				<div className="ticket-conversation">
					<div className="ticket-conversation-messages">
						{conversation.loading && !conversation.called ? (
							<div className="loading">
								<Spin />
							</div>
						) : null}

						{!messages.length &&
						conversation.called &&
						!conversation.loading ? (
							<div className="empty">
								<Empty description={"Nenhuma mensagem"} />
							</div>
						) : null}

						{messages?.length
							? messages.map((item) => {
									return (
										<Comment
											key={
												item?._id
													? item._id
													: Math.floor(Math.random() * 1000000000000000)
											}
											actions={null}
											className={item?.author?.me ? "me" : "default"}
											author={renderAuthor(item?.author)}
											avatar={null}
											content={item?.message}
											datetime={dayjs(item?.createdAt).fromNow()}
										/>
									);
							  })
							: null}
						<div ref={scrollRef}></div>
					</div>

					<div className="ticket-conversation-textarea">
						<Form onFinish={sendMessage}>
							<TextArea
								rows={4}
								value={state?.message ?? null}
								onChange={(e) => {
									setState((d) => {
										d.message = e.target.value;
									});
								}}
							/>
							<Button
								type="primary"
								style={{ marginTop: 20, float: "right" }}
								htmlType="submit"
								disabled={!state?.message?.length || sendingLoading}
								loading={sendingLoading}
							>
								Enviar
							</Button>
						</Form>
					</div>
				</div>
			</Drawer>
			<Badge count={badgeValue > 0 ? badgeValue : 0}>
				<Button
					shape="circle"
					icon={<MessageOutlined />}
					size="large"
					onClick={() => setOpen(true)}
				/>
			</Badge>
		</div>
	);
};

export default Balance;
