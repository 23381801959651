import React, { useEffect } from "react";
import Loading from "components/Loading/Page";
import Client from "functions/apollo";
import User from "store/user";
import Emitter from "functions/emitter";

import { gql } from "@apollo/client";

function AppWrapper({ children, client }) {
	const [loading, setLoading] = React.useState(true);

	useEffect(() => {

		

		try {
			let data = localStorage.getItem("user");
			if (data) {
				data = JSON.parse(data);

				client
					.query({
						query: gql`
              				query AuthCheck($token: String!) {
									AuthCheck(token: $token) {
										pending
										result
									}
								}
            `,
						fetchPolicy: "no-cache",
						variables: {
							token: data.token,
						},
					})
					.then(async (check) => {

						if (check.data.AuthCheck.result) {
							User.setData(data).then(() => {
								client
									.query({
										query: gql`
                    query GetUserType {
                      GetUserType {
                        type
                        subtype
                      }
                    }
                  `,
										fetchPolicy: "no-cache",
									})
									.then(({ data }) => {
										User.info = data.GetUserType;
										setLoading(false);
									});
							});
						} else {
							await localStorage.removeItem("user");
							setLoading(false);
						}
					});
			} else {
				setLoading(false);
			}
		} catch (e) {
			setLoading(false);
		}

		Emitter.on("LOGOUT", async function () {
			await localStorage.removeItem("user");
			setLoading(false);
		});

		return () => {
			Emitter.off("LOGOUT");
		};
	}, []);

	if (loading) return <Loading />;
	return children;
}

export default AppWrapper;
