import React, { useState, useEffect, useRef } from "react";
import {
	Modal,
	Button,
	Col,
	Row,
	Layout,
	Form,
	Input,
	Select,
	Alert,
	DatePicker,
	Upload,
	Radio,
	notification,
	Divider,
} from "antd";

import Swal from "sweetalert2";
import { gql } from "@apollo/client";
import { LoadingOutlined } from "@ant-design/icons";
import MaskedInput from "antd-mask-input";
import _ from "lodash";
import Estados from "functions/states";
import User from "store/user";
import isEmail from "validator/lib/isEmail";

import * as Queries from "./Queries";
import { useMutation } from "@apollo/client";
import useImperativeQuery from "functions/hooks/useImperativeQuery";

const { Footer, Content } = Layout;
const { Option } = Select;

function Main(props) {
	const getData = useImperativeQuery(Queries.GET);
	const [update, { loading }] = useMutation(Queries.UPDATE);
	const [requesting, setRequest] = useState(true);
	const [state, setState] = useState({});
	const [hasData, setHasData] = useState(null);

	const setValue = (path, value) => {
		return setState((state) => {
			let obj = { ...state };
			_.set(obj, path, value);
			return obj;
		});
	};

	const getValue = (path) => {
		return _.get(state, path);
	};

	const ErrorDialog = (msg) => {
		Swal.fire({
			title: "Erro",
			text: msg,
			icon: "error",
			confirmButtonText: "OK",
		});
	};

	useEffect(() => {
		async function getDataQuery() {
			const res = await getData();
			if (res?.data?.GetUserInfo) {
				let insetData = JSON.parse(JSON.stringify(res.data.GetUserInfo));
				if (insetData?.business?.activity) {
					insetData.business.activity = insetData.business.activity.toString();
				}
				setState(insetData);
				setHasData(true);
			} else {
				setHasData(false);
			}
			setRequest(false);
		}
		User.check(props.client).then(
			(res) => {
				if (res.result) {
					if (res.pending) {
						getDataQuery();
					} else {
						props.history.replace("/dashboard");
					}
				}
			},
			() => {
				props.history.replace("/login");
			}
		);
	}, []);

	const save = async () => {
		if (!getValue("business.company_name"))
			return ErrorDialog("Por favor, insira uma Razão Social válida");
		if (
			!getValue("business.cnpj") ||
			getValue("business.cnpj").length < "11.111.111/1111-11"
		)
			return ErrorDialog("Por favor, insira um CNPJ válido");
		if (!getValue("business.trade_name"))
			return ErrorDialog("Por favor, insira um nome fantasia válido");
		if (!getValue("business.email") || !isEmail(getValue("business.email")))
			return ErrorDialog("Por favor, insira um e-mail válido");
		if (!getValue("business.activity"))
			return ErrorDialog("Por favor, selecione uma atividade");

		if (
			!getValue("address.zip_code") ||
			getValue("address.zip_code").length < "00000-000".length
		)
			return ErrorDialog("Por favor, informe um CEP.");
		if (!getValue("address.street"))
			return ErrorDialog("Por favor, informe um endereço.");
		if (!getValue("address.number"))
			return ErrorDialog("Por favor, informe um número.");
		if (!getValue("address.neighborhood"))
			return ErrorDialog("Por favor, informe um bairro.");
		if (!getValue("address.city"))
			return ErrorDialog("Por favor, informe uma cidade.");
		if (!getValue("address.state"))
			return ErrorDialog("Por favor, selecione um estado.");

		// if(!getValue('documents.mother_name'))
		// 	return ErrorDialog('Por favor, informe o nome da mãe.');
		// if(!getValue('documents.rg'))
		// 	return ErrorDialog('Por favor, informe um RG.');
		// if(!getValue('documents.birth_date'))
		// 	return ErrorDialog('Por favor, informe uma data de nascimento válida.');

		// const {business, ...rest} = state;
		// const {cnpj, ...bizrest} = business;

		let input = {
			...state,
		};

		//
		// Converts
		//

		input.business.activity = parseInt(input.business.activity);

		//
		// Update
		//

		const response = await update({
			variables: { input },
		});
		const { data, error } = response;

		if (error) {
			return ErrorDialog(
				"Não foi possível atualizar as informações no momento. Por favor, tente novamente mais tarde. Se o problema persistir, entre em contato com nosso suporte."
			);
		} else {
			props.history.replace("/");
		}
	};

	// console.log(data);

	if (requesting)
		return (
			<div className="loading-view">
				<div className="spinner-wrapper">
					<LoadingOutlined />
				</div>
			</div>
		);

	return (
		<Layout className="main-wrapper">
			<div className="user-info-view">
				<div className="user-info-wrapper">
					{hasData === true ? (
						<>
							<h2>Confirme suas Informações</h2>
							<p>
								Antes de prosseguir para seu painel, por favor, confirme seus
								dados de cadastro.
							</p>
						</>
					) : null}

					{hasData === false ? (
						<>
							<h2>Informações Adicionais</h2>
							<p>
								Antes de prosseguir para seu painel, por favor, preencha os
								dados a seguir.
							</p>
						</>
					) : null}

					<br />
					<h3>Sobre o seu MEI</h3>

					<Form onSubmit={null} layout={"vertical"} className="user-info-form">
						<Form.Item label="CNPJ">
							<MaskedInput
								mask={"00.000.000/0000-00"}
								maskOptions={{
									placeholderChar: "",
									lazy: true,
								}}
								disabled
								defaultValue={getValue("business.cnpj")}
								onChange={(e) => {
									const { value } = e.target;
									setValue("business.cnpj", value);
								}}
							/>
						</Form.Item>

						<Form.Item label="Razão Social">
							<Input
								defaultValue={getValue("business.company_name")}
								onChange={(e) => {
									const { value } = e.target;
									setValue("business.company_name", value);
								}}
							/>
						</Form.Item>

						<Form.Item label="Nome Fantasia">
							<Input
								defaultValue={getValue("business.trade_name")}
								onChange={(e) => {
									const { value } = e.target;
									setValue("business.trade_name", value);
								}}
							/>
						</Form.Item>

						<Form.Item label="E-mail da Empresa">
							<Input
								defaultValue={getValue("business.email")}
								onChange={(e) => {
									const { value } = e.target;
									setValue("business.email", value);
								}}
							/>
						</Form.Item>

						<Form.Item label="Inscrição Municipal">
							<Input
								defaultValue={getValue("business.municipal_registration")}
								onChange={(e) => {
									const { value } = e.target;
									setValue("business.municipal_registration", value);
								}}
							/>
						</Form.Item>

						<Form.Item name="activity" label="Atividade">
							<Select
								defaultValue={getValue("business.activity")}
								onChange={(e) => {
									setValue("business.activity", e);
								}}
							>
								<Select.Option value="1">Comércio ou Indústria</Select.Option>
								<Select.Option value="2">Prestação de Serviços</Select.Option>
								<Select.Option value="3">Comércio e Serviços</Select.Option>
							</Select>
						</Form.Item>

						<br />

						<h3>Endereço</h3>

						<Row gutter={20}>
							<Col span={6}>
								<Form.Item name="cep" label="CEP">
									<MaskedInput
										mask={"00000-000"}
										maskOptions={{
											placeholderChar: "",
											lazy: true,
										}}
										defaultValue={getValue("address.zip_code")}
										onChange={(e) => {
											const { value } = e.target;
											setValue("address.zip_code", value);
										}}
									/>
								</Form.Item>
							</Col>
							<Col span={18}>
								<Form.Item name="endereco" label="Endereço">
									<Input
										defaultValue={getValue("address.street")}
										onChange={(e) => {
											const { value } = e.target;
											setValue("address.street", value);
										}}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item name="number" label="Número">
									<Input
										defaultValue={getValue("address.number")}
										onChange={(e) => {
											const { value } = e.target;
											setValue("address.number", value);
										}}
									/>
								</Form.Item>
							</Col>

							<Col span={12}>
								<Form.Item name="complement" label="Complemento">
									<Input
										defaultValue={getValue("address.complement")}
										onChange={(e) => {
											const { value } = e.target;
											setValue("address.complement", value);
										}}
									/>
								</Form.Item>
							</Col>

							<Col span={24}>
								<Form.Item name="neighborhood" label="Bairro">
									<Input
										defaultValue={getValue("address.neighborhood")}
										onChange={(e) => {
											const { value } = e.target;
											setValue("address.neighborhood", value);
										}}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item name="city" label="Cidade">
									<Input
										defaultValue={getValue("address.city")}
										onChange={(e) => {
											const { value } = e.target;
											setValue("address.city", value);
										}}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item name="uf" label="Estado">
									<Select
										defaultValue={getValue("address.state")}
										onChange={(e) => {
											setValue("address.state", e);
										}}
									>
										{Estados("all").map((item, index) => {
											return (
												<Select.Option
													key={`state_${index}`}
													value={item.value}
												>
													{item.text}
												</Select.Option>
											);
										})}
									</Select>
								</Form.Item>
							</Col>
						</Row>

						<br />
						<h3>Outros dados</h3>

						<Row gutter={20}>
							<Col span={24}>
								<Form.Item name="mother_name" label="Nome da Mãe">
									<Input
										defaultValue={getValue("documents.mother_name")}
										onChange={(e) => {
											const { value } = e.target;
											setValue("documents.mother_name", value);
										}}
									/>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item name="rg" label="RG">
									<Input
										defaultValue={getValue("documents.rg")}
										onChange={(e) => {
											const { value } = e.target;
											setValue("documents.rg", value);
										}}
									/>
								</Form.Item>
							</Col>

							<Col span={12}>
								<Form.Item name="birth_date" label="Data de Nascimento">
									<MaskedInput
										mask={"00/00/0000"}
										maskOptions={{
											placeholderChar: "",
											lazy: true,
										}}
										defaultValue={getValue("documents.birth_date")}
										onChange={(e) => {
											const { value } = e.target;
											setValue("documents.birth_date", value);
										}}
									/>
								</Form.Item>
							</Col>

							<Col span={12}>
								<Form.Item name="voter_title" label="Título de Eleitor">
									<Input
										defaultValue={getValue("documents.voter_title")}
										onChange={(e) => {
											const { value } = e.target;
											setValue("documents.voter_title", value);
										}}
									/>
								</Form.Item>
							</Col>

							<Col span={12}>
								<Form.Item name="tax_code" label="Número Imposto de Renda">
									<Input
										defaultValue={getValue("documents.tax_code")}
										onChange={(e) => {
											const { value } = e.target;
											setValue("documents.tax_code", value);
										}}
									/>
								</Form.Item>
							</Col>
						</Row>

						<Divider />

						<Row>
							<Col span={24}>
								<Button
									type="primary"
									style={{ float: "right" }}
									onClick={save}
									loading={loading}
									disabled={loading}
								>
									Salvar
								</Button>
							</Col>
						</Row>
					</Form>
				</div>
			</div>
		</Layout>
	);
}

export default Main;
