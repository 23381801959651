import { Menu, Badge, Button } from "antd";
import {
	AppstoreOutlined,
	DollarOutlined,
	UserOutlined,
	WalletOutlined,
	FileDoneOutlined,
	AreaChartOutlined,
	ShoppingOutlined,
	ReadOutlined,
	CarryOutOutlined,
	TeamOutlined,
	ImportOutlined,
	ExportOutlined,
	FileSyncOutlined,
	BuildOutlined,
	FileTextOutlined,
} from "@ant-design/icons";
import { gql, useQuery, useMutation } from "@apollo/client";
import CertificatePurchase from "components/CertificatePurchase";
import Insurance from "components/Insurance";

const MenuRoutes = ({ handleClick, keys }) => {
	const { data } = useQuery(
		gql`
		{
			UserBadges {
				dasInstallments
			}
		}
	`,
		{
			fetchPolicy: "no-cache",
		}
	);

	const [saveLead] = useMutation(gql`
		mutation SaveLead($input: LeadInput!)	 {
			SaveLead(input: $input)
		}
	`);

	const links = useQuery(
		gql`
		query GetAvailableLinks($app: String!) {
			GetAvailableLinks(app: $app)
		}
	`,
		{
			fetchPolicy: "no-cache",
			variables: {
				app: import.meta.env.VITE_APP_THEME || "openmei",
			},
		}
	);

	return (
		<>
			<div className="menu-wrapper">
				<Menu
					onClick={handleClick}
					style={{ width: 280 }}
					defaultSelectedKeys={keys}
					selectedKeys={keys}
					openKeys={["/reps", "/invoices-wrap"]}
					mode="inline"
					expandIcon={<span></span>}
				>
					<Menu.Item icon={<AppstoreOutlined />} key={"/"}>
						Dashboard
					</Menu.Item>

					{/*
                 <Menu.Item icon={<CalendarOutlined />} key={'/schedules'}>
                     Agenda
                 </Menu.Item>
                 */}

					{/*
                <Menu.Item icon={<DollarOutlined />} key={'/sales'}>
                    Vendas
                </Menu.Item>
                */}

					<Menu.Item icon={<DollarOutlined />} key={"/cash-launch"}>
						Caixa
					</Menu.Item>
					<Menu.Item icon={<ExportOutlined />} key={"/bills/expenses"}>
						Contas a Pagar
					</Menu.Item>
					<Menu.Item icon={<ImportOutlined />} key={"/bills/income"}>
						Contas a Receber
					</Menu.Item>
					<Menu.Item icon={<TeamOutlined />} key={"/clients"}>
						Clientes
					</Menu.Item>
					<Menu.Item icon={<BuildOutlined />} key={"/suppliers"}>
						Fornecedores
					</Menu.Item>
					<Menu.Item icon={<ShoppingOutlined />} key={"/products"}>
						Meus Produtos
					</Menu.Item>

					<Menu.Item icon={<ReadOutlined />} key={"/stock"}>
						Controle de Estoque
					</Menu.Item>
					<Menu.Item icon={<WalletOutlined />} key={"/das"}>
						DAS
					</Menu.Item>
					<Menu.Item icon={<WalletOutlined />} key={"/das-installment"}>
						DAS de Parcelamento
						{data?.UserBadges?.dasInstallments ? (
							<Badge
								count={data?.UserBadges?.dasInstallments}
								style={{ marginLeft: 10, marginTop: -2 }}
							/>
						) : null}
					</Menu.Item>

					<Menu.Item icon={<FileSyncOutlined />} key={"/documents"}>
						Docs. Enviados/Recebidos
					</Menu.Item>

					<Menu.Item icon={<WalletOutlined />} key={"/active-debts"}>
						Dívida ativa
					</Menu.Item>

					<Menu.SubMenu
						icon={<FileTextOutlined />}
						title="Notas Fiscais"
						key="/invoices-wrap"
					>
						<Menu.Item key={"/invoices?new=true"}>Emitir Nota Fiscal</Menu.Item>
						<Menu.Item key={"/invoices"}>Notas Emitidas</Menu.Item>
					</Menu.SubMenu>

					<Menu.Item icon={<FileDoneOutlined />} key={"/receipts"}>
						Recibos
					</Menu.Item>

					<Menu.SubMenu
						icon={<AreaChartOutlined />}
						title="Relatórios"
						key="/reps"
					>
						<Menu.Item key={"/reports/income"}>
							Relatório de Faturamento
						</Menu.Item>
						<Menu.Item key={"/reports/expenses"}>
							Relatório de Despesa
						</Menu.Item>
					</Menu.SubMenu>

					<Menu.Item icon={<CarryOutOutlined />} key={"/services"}>
						Serviços
					</Menu.Item>

					<Menu.Item icon={<CarryOutOutlined />} key={"/tutorials"}>
						Tutoriais
					</Menu.Item>

					<Menu.Item icon={<CarryOutOutlined />} key={"/documents/important"}>
						Documentos Importantes
					</Menu.Item>

					<Menu.Item icon={<UserOutlined />} key={"/profile"}>
						Meu Perfil
					</Menu.Item>
				</Menu>

				{links?.data?.GetAvailableLinks?.length &&
				links?.data?.GetAvailableLinks.indexOf("certificate") > -1 ? (
					<CertificatePurchase />
				) : null}

				{links?.data?.GetAvailableLinks?.length &&
				links?.data?.GetAvailableLinks.indexOf("santander") > -1 ? (
					<div
						style={{
							padding: 10,
							paddingTop: 0,
						}}
					>
						{import.meta.env.VITE_APP_THEME === "simei" ? (
							<Button
								type="primary"
								style={{
									width: "100%",
									color: "white",
									backgroundColor: "#324396",
									borderColor: "#324396",
								}}
								onClick={() => {
									saveLead({
										variables: {
											input: {
												source: "r2bank",
											},
										},
									});

									let url =
										"https://app.r2bank.com.br/login";

									window.open(url);
								}}
							>
								R2 BANK
							</Button>
						) : (
							<Button
								type="primary"
								style={{
									width: "100%",
									color: "white",
									backgroundColor: "#cc0100",
									borderColor: "#cc0100",
								}}
								onClick={() => {
									saveLead({
										variables: {
											input: {
												source: "santander",
											},
										},
									});

									let url =
										"https://www.santander.com.br/conta-empresarial?id=5e0015c64068c090fcb46595205df9b7da21ecfc508d3b96e57e12c8283a4977";

									if (import.meta.VITE_APP_THEME === "simei") {
										url = "";
									}

									window.open(url);
								}}
							>
								Santander MEI+
							</Button>
						)}
					</div>
				) : null}

				{links?.data?.GetAvailableLinks?.length &&
				links?.data?.GetAvailableLinks.indexOf("insurance") > -1 ? (
					<Insurance />
				) : null}

				{links?.data?.GetAvailableLinks?.length &&
				links?.data?.GetAvailableLinks.indexOf("inove") > -1 ? (
					<div
						style={{
							padding: 10,
							paddingTop: 0,
						}}
					>
						<Button
							type="primary"
							style={{
								width: "100%",
								color: "white",
								backgroundColor: "#f48120",
								borderColor: "#f48120",
							}}
							onClick={() => {
								window.open("http://www.inoveclick.com.br/");
								saveLead({
									variables: {
										input: {
											source: "inoveclick",
										},
									},
								});
							}}
						>
							Marketing Digital
						</Button>
					</div>
				) : null}
			</div>
		</>
	);
};

export default MenuRoutes;
