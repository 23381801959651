import { useEffect, useState } from "react";
import { Button, Modal, Spin, Empty, Card, Row, Col, notification } from "antd";
import { gql, useLazyQuery, useMutation } from "@apollo/client";

const CertificatePurchase = ({ handleClick, keys }) => {
	const [modal, setModal] = useState({
		open: false,
	});

	const [getCertificates, { loading, data }] = useLazyQuery(
		gql`
		query GetInsuranceLinks {
			GetInsuranceLinks {
				name
            url
			}
		}
	`,
		{
			fetchPolicy: "no-cache",
		}
	);

	const [saveLead] = useMutation(gql`
		mutation SaveLead($input: LeadInput!)	 {
			SaveLead(input: $input)
		}
	`);


	return (
		<>
			<Modal
				title="Cotação de Seguro"
				open={modal.open}
				onOk={() => {
					setModal({ open: false });
				}}
				width={800}
				onCancel={() => {
					setModal({ open: false });
				}}
				footer={null}
			>
				{loading ? (
					<div style={{ padding: 20, textAlign: "center" }}>
						<Spin />
					</div>
				) : null}

				{!loading && data?.GetInsuranceLinks?.length ? (
					<Row gutter={20}>
						{data.GetInsuranceLinks.map((item, index) => {
							return (
								<Col span={8}>
									<Card key={`cert_${index}`}>
										<p>{item?.name ?? "-"}</p>
										<Button
											type="primary"
											onClick={() => {
												saveLead({
													variables: {
														input: {
															source: "insurance"
														}
													}
												})
												window.open(item.url);
											}}
										>
											Selecionar
										</Button>
									</Card>
								</Col>
							);
						})}
					</Row>
				) : null}

				{!loading && !data?.GetInsuranceLinks?.length ? (
					<Empty description={"Nenhum seguro encontrado"} />
				) : null}
			</Modal>

			<div style={{ padding: 10, paddingTop: 0 }}>
				<Button
					type="primary"
					style={{
						width: "100%",
						color: "white",
						backgroundColor: "#389ff3",
						borderColor: "#389ff3",
					}}
					onClick={() => {
						getCertificates();
						setModal({ open: true });
					}}
				>
					Cotação de Seguro
				</Button>
			</div>
		</>
	);
};

export default CertificatePurchase;
